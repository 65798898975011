Drupal.behaviors.gnavWishlist = (function ($, _, site) {
  site.userInfoCookie.init();
  'use strict';
  // Private variables:
  var $wishlistCounters = $();
  var data = {
    wishlist_item_count: parseInt(site.userInfoCookie.getValue('fav_item_count')),
    wishlist_items: []
  };
  // Public methods:
  var behavior = {
    attach: function (context) {
      $wishlistCounters = $wishlistCounters.add($('.js-gnav-util-icon-wishlist-count', context));

      // Load wishlist counter first time
      behavior.updateWishlistCounter(data.wishlist_item_count);

      // Document listeners:
      $(document).on('addToWishlist.success', function (event) {
        event.preventDefault();
        var currentCounter = parseInt($wishlistCounters.text()) + 1;

        behavior.updateWishlistCounter(currentCounter);
      });
    },

    updateWishlistCounter: function (item_count) {
      if (item_count > 0) {
        // Update gnav header wishlist counter mobile
        setTimeout(function () {
          $(document).trigger('update_gnav_header_wishlist_counter', item_count);
        }, 2000);
        // Update wishlist counter PC
        $wishlistCounters.text(item_count);
      }
    }
  };

  return behavior;
})(
  window.jQuery = window.jQuery || function () {},
  window._ = window._ || {},
  window.site = window.site || {}
);
